import React from "react";
import SingleDropdown from "./SingleDropdown";

const FlyttstadningQ = () => {
  return (
    <div class="bg-gray-50">
      <div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div class="max-w-3xl mx-auto">
          <h2 class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Det här ingår i flyttstädningen
          </h2>
          <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500 ta-center ">
            Se vad vårt grundpaket innehåller. Det går självklart att lägga till
            de tjänster du önskar. Med utbildade städare och miljövänliga
            städmaterial ser vi till att erbjuda bästa möjliga städning.
          </p>
          <SingleDropdown
            text="Allmänt / Alla utrymmen"
            items={[
              "Putsar fönster.",
              "Dammsuger och våttorkar snickerier, lister, dörrar, dörrkarmar, fönsterbrädor, fria ytor, garderobsdörrar och skåpdörrar.",
              "Torkar ovanpå och invändigt i garderober, skåp, och bokhyllor.",
              "Dammsuger och våttorkar element.",
              "Avlägsnar fläckar från strömbrytare.",
              "Dammtorkar eluttag.",
              "Dammtorkar väggar.",
              "Dammtorkar lampor.",
              "Putsar speglar.",
              "Dammsuger och moppar golv.",
            ]}
          />
          <SingleDropdown
            text="Kök (inkl. allmänna moment)"
            items={[
              "Rengör kyl och frys ut- och invändigt.",
              "Rengör spis och ugn ut- och invändigt.",
              "Rengör ugnsgaller och ugnsplåt (1st ingår).",
              "Rengör fläkt och fläktfilter.",
              "Rengör ut- och invändigt samt ovanpå skåp, hyllor och lådor.",
              "Rengör kakel/stänkskydd ovanför diskbänk.",
              "Rengör mikrovågsugn ut- och invändigt.",
              "Putsar diskho, blandare, propp och sil.",
              "Rengör köksbänk.",
            ]}
          />
          <SingleDropdown
            text="Toalett / Badrum (inkl. allmänna moment)"
            items={[
              "Rengör väggar och golv.",
              "Avfettar och avkalkar väggar där det är möjligt.",
              "Rengör hela duschen alt. badkaret.",
              "Rengör golvbrunn.",
              "Putsar blandare och duschmunstycke.",
              "Rengör synliga rör.",
              "Torkar ut- och invändigt samt ovanpå badrumsskåp.",
              "Rengör utvändigt vitvaror.",
              "Rengör kran och handfat.",
              "Rengör ut- och invändigt toalettstolen.",
            ]}
          />
          <SingleDropdown
            text="Tjänster som kan bokas separat"
            items={[
              "Stäng av och frosta av kyl och frys.",
              "Dra ut kyl, frys, spis och tvättmaskin från väggen om du vill att vi rengör bakom.",
              "Skruva upp fönster som kräver det vid beställning av 4-sidig fönsterputs.",
              "Montera av eventuell badkarsfront för att vi ska komma åt under och rengöra.",
              "Informera oss gärna om du har känsliga material, t.ex. marmor eller mässing i din bostad.",
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default FlyttstadningQ;
