import React from "react";
import SingleDropdown from "./SingleDropdown";

const FAQ = () => {
  return (
    <div class="bg-gray-50">
      <div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        {/* <h2 class="text-center font-semibold tracking-wider text-green-600 uppercase">
          Allt, och lite till.
        </h2> */}
        <div class="max-w-3xl mx-auto">
          <h2 class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Vanliga frågor och svar
          </h2>
          <SingleDropdown
            text="Vad kostar flyttstädningen?"
            description="Vi flyttstädar alltid till ett fast pris helt utan extra eller dolda avgifter. Ange hur stor din bostad är och vad du önskar hjälp med i vår kalkylator, så får du direkt en prisuppskattning."
          />
          <SingleDropdown
            text="Vad innebär kvalitetsgarantin? "
            description="Vi vill bara ha nöjda kunder! Med 5 dagars kvalitetsgaranti kan vi garantera en enklare vardag, där vi snabbt kan ställa allt till rätta om det skulle vara något du är mindre nöjd med."
          />
          <SingleDropdown
            text="Hur lång tid tar städningen? "
            description="Det beror på hur smutsigt det är, men oavsett hur stort det är blir vi alltid färdiga samma dag, och utför arbetet någon gång mellan kl. 8-17."
          />
          <SingleDropdown
            text="Hur gör jag för att lämna nycklar inför flyttstädningen?"
            description="Du kan lämna nyckel till oss på kontoret senast kl. 12:00 en vardag innan flyttstädningen. Nycklar används för att utföra städningen, och lämnas därefter i din brevlåda om inget annat angetts. Adress till närmaste kontoret hittar du vid bokningen och i bekräftelsemailet."
          />
          <SingleDropdown
            text="Vilka städredskap och rengöringsmedel behöver jag ha hemma? "
            description="Ingenting alls! Våra städare tar med sig allt städmaterial och miljövänliga rengöringsmedel som behövs för att utföra städningen. "
          />
          <SingleDropdown
            text="Hur betalar jag för flyttstädningen?"
            description="För att slippa krångel vid adressbyten skickas en faktura till din e-mail som du angett vid bokningen, några dagar efter utförd städning. Därefter har du 15 dagar på dig att betala i lugn och ro."
          />
          <SingleDropdown
            text="Vad händer om något går sönder när ni städar hos mig? "
            description="Dreamclean har försäkringar som täcker eventuella skador på egendom som vår personal orsakar under ett städpass hemma hos dig. "
          />
          <SingleDropdown
            text="Hur funkar rut-avdraget? "
            description="Hushållsnära tjänster ger dig som konsument en skattereduktion på 50% av totalbeloppet, vilket innebär att du bara betalar halva priset. Avdraget görs direkt av oss."
            link
          />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
